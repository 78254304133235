import actions from "@/qiankun/actions";

let data = <any>actions.actions;
let request = data.request;
// 查询班次管理列表
export function listWorkShift(query: any) {
  return request({
    url: "/factory/workShift/list",
    method: "get",
    params: query,
  });
}

// 查询班次管理详细
export function getWorkShift(query: any) {
  return request({
    url: "/factory/workShift/getInfo",
    method: "get",
    params: query,
  });
}

// 新增班次管理
export function addWorkShift(data: any) {
  return request({
    url: "/factory/workShift",
    method: "post",
    data: data,
  });
}

// 修改班次管理
export function updateWorkShift(data: any) {
  return request({
    url: "/factory/workShift",
    method: "put",
    data: data,
  });
}

// 删除班次管理
export function delWorkShift(data: any) {
  return request({
    url: "/factory/workShift/removes",
    method: "delete",
    data: data,
  });
}

// 下拉列表
export function workShiftSelectList() {
  return request({
    url: "/factory/workShift/selectList",
    method: "get",
  });
}
